import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';
import {Municipality} from './realEstatesStore';

class Store {
    provinces: Province[] = [];
    municipalities: Municipality[] = [];
    constructor() {
        makeObservable(this, {
            provinces: observable,
            municipalities: observable,
            loadStore: action,
            getMunicipalities: action,
            getProvinces: action,
            setProvinces: action,
            setMunicipalities: action,
            createMunicipality: action,
            setMunicipality: action,
        });
    }

    async loadStore() {
        const provinces = await api.request('GET', '/provinces');
        this.setProvinces(provinces);
        const municipalities1 = await api.request('GET', '/municipality?provinceName=Valencia');
        this.setMunicipalities(municipalities1);
        const municipalities2 = await api.request('GET', '/municipality?provinceName=Barcelona');
        this.setMunicipalities(municipalities2);
    }

    getMunicipalities(provinceName: string) {
        return this.municipalities.filter((municipality) => municipality.state_name === provinceName);
    }

    getProvinces() {
        return this.provinces;
    }

    getProvince(provinceName: string): Province {
        // @ts-ignore
        return this.provinces.find((province) => province.name === provinceName);
    }

    setProvinces(provinces: Province[]) {
        this.provinces = provinces;
    }

    setMunicipalities(municipalities: Municipality[]) {
        this.municipalities = this.municipalities.concat(municipalities);
    }

    async createMunicipality(data: Municipality) {
        const municipality = await api.request('POST', '/municipality', data);
        this.setMunicipality(municipality);
        return municipality;
    }

    setMunicipality(municipality: Municipality) {
        this.municipalities.push(municipality);
    }
}

export type Province = {
    province_id: number;
    name: string;
    country_id: number;
    country_code: string;
    country_name: string;
    state_code: string;
    type: string;
    latitude: string;
    longitude: string;
    locationRestriction: {
        east: number;
        north: number;
        south: number;
        west: number;
    }
}

export type ProvinceStore = {
    provinces: Province[];
    municipalities: Municipality[];
    loadStore: () => Promise<any>;
    getMunicipalities: (provinceName: string) => Municipality[];
    setProvinces: (provinces: Province[]) => void;
    setMunicipalities: (municipalities: Municipality[]) => void;
    getProvince: (provinceName: string) => Province;
    getProvinces: () => Province[];
}

export const provinceStore = new Store();
export const provinceStoreContext = createContext(provinceStore);
