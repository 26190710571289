import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';

class Store {
    isAuthorized: boolean = false;
    userPreferences: User = {} as User;
    userAuthorized = {} as any;
    users: UserWithAuth[] = [];

    constructor() {
        makeObservable(this, {
            isAuthorized: observable,
            userPreferences: observable,
            userAuthorized: observable,
            users: observable,

            loadStore: action,
            setUsers: action,
            setAuthorized: action,
            setUserAuthorized: action,
            setUserPreferences: action,
            getUserPreferences: action,
            isAdmin: action,
            getUser: action,
            addUser: action,
            editUser: action,
            createUser: action,
            updateUser: action,
            checkPermissionAccess: action,
        });
    }

    async loadStore(user: any) {
        try {
            await this.setAuthorized(user);
            await this.getUserPreferences(user.uid);
            const users = await api.request('GET', '/users');
            this.setUsers(users);
        } catch (err) {
            console.log('[usersStore.ts][loadStore] error: ', err);
        }
    }

    setUsers(users: any) {
        this.users = users;
    }

    async setAuthorized(user: any) {
        this.isAuthorized = !!user;
        if (this.isAuthorized) {
            api.token = await user.auth.currentUser.getIdToken(true);
            this.setUserAuthorized(user);
        }
    }

    setUserAuthorized(user: any) {
        this.userAuthorized = user;
    }

    setUserPreferences(userPreferences: User) {
        this.userPreferences = userPreferences;
    }

    async getUserPreferences(id: string) {
        try {
            const userPreferences = await api.request('GET', `/user/${id}`);
            this.setUserPreferences(userPreferences);
            return userPreferences;
        } catch (err) {
            return err;
        }
    }

    isAdmin() {
        return this.userPreferences.role === 'admin';
    }

    getUser(userId: string){
        return this.users.find((user) => user.id === userId) || null;
    }

    addUser(user: UserWithAuth) {
        this.users.push(user);
    }

    editUser(user: UserWithAuth) {
        const index = this.users.findIndex((u: UserWithAuth) => u.id === user.id);
        this.users[index] = {...this.users[index], ...user};
    }

    async createUser(user: {email: string; password: string; role: string; permissions: any[], provinces: any[]}) {
        try {
            const createdUser = await api.request('POST', '/user', user);
            this.addUser(createdUser);
        } catch (err) {
            return err;
        }
    }

    async updateUser(id: string, user: {role: string; permissions: any[], provinces: any[]}) {
        try {
            const updatedUser = await api.request('PUT', `/user/${id}`, user);
            this.editUser(updatedUser);
        } catch (err) {
            return err;
        }
    }

    checkPermissionAccess(permission: string) {
        return (this.userPreferences.permissions || []).includes(permission)
    }
}

export type User = {
    id?: string;
    googleId: string;
    role: string;
    permissions: string[];
    provinces: string[];
}

export type UserWithAuth = User & {
    email: string;
    disabled: boolean;
    emailVerified: boolean;
}

export type UsersStore = {
    users: UserWithAuth[];
    isAuthorized: boolean;
    userPreferences: User;
    userAuthorized: any;
    setAuthorized: (user: any) => void;
    setUserAuthorized: (user: any) => void;
    isAdmin: () => boolean;
    getUserPreferences: (id: string) => Promise<User>;
    loadStore: (user: any) => void;
    getUser: (userId: string) => UserWithAuth | null;
    addUser: (user: UserWithAuth) => void;
    editUser: (user: UserWithAuth) => void;
    createUser: (user: {email: string; password: string; role: string; permissions: any[], provinces: any[]}) => void;
    updateUser: (id: string, user: {role: string; permissions: any[], provinces: any[]}) => void;
    checkPermissionAccess: (permission: string) => boolean;
}

export const usersStore = new Store();
export const usersStoreContext = createContext(usersStore);
