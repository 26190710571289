import React, {useContext, useEffect, useState} from 'react';
import './Provinces.scss';
import {observer} from 'mobx-react';
import {Province, provinceStoreContext} from '../../store/components/provinceStore';
import {Municipality} from '../../store/components/realEstatesStore';
import {Button, Card, Col, Input, Row} from 'reactstrap';
import {ContentEditableEvent} from 'react-simple-wysiwyg';
import {clone} from '../../utils/functions';

const Provinces = observer(() => {
    const provinceStoreCtx = useContext(provinceStoreContext);
    const [provinces] = useState<Province[]>(provinceStoreCtx.getProvinces());
    const [province, setProvince] = useState<Province>(provinceStoreCtx.getProvince('Valencia'));
    const [municipalities, setMunicipalities] = useState<Municipality[]>(provinceStoreCtx.getMunicipalities(province.name));
    const [municipalityState, setMunicipalityState] = useState({
        name: '',
        latitude: '',
        longitude: '',
    });
    const [isCreateShow, setCreateShow] = useState(false);
    const [searchState, setSearchState] = useState({
        searchTerm: '',
        municipalities: clone(municipalities)
    });

    useEffect(() => {
        setMunicipalities(provinceStoreCtx.getMunicipalities(province.name));
        setSearchState({...searchState, ...{municipalities: provinceStoreCtx.getMunicipalities(province.name)}})
    }, [province, provinceStoreCtx]);

    const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProvince(provinceStoreCtx.getProvince(event.target.value));
    }

    const merge = (data: any) => {
        const state = {...municipalityState, ...data};
        setMunicipalityState({...municipalityState, ...state});
    }

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement> | ContentEditableEvent, field: string) => {
        let value = event.target.value;
        merge({[field]: value});
    }

    const handleFilterMunicipality = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        const municipalitiesClone = clone(municipalities);
        if (!searchTerm) {
            return setSearchState({municipalities: municipalitiesClone, searchTerm: ''});
        }

        return setSearchState({
            municipalities: municipalitiesClone.filter((municipality: Municipality) => municipality.name.toLowerCase().includes(searchTerm)),
            searchTerm: searchTerm
        });
    }

    const handleCreateMunicipality = async () => {
        const data = {
            ...municipalityState,
            ...{
                state_id: province.province_id,
                state_code: province.state_code,
                state_name: province.name,
                country_id: province.country_id,
                country_code: province.country_code,
                country_name: province.name,
                wikiDataId: null,
            }
        }

        await provinceStoreCtx.createMunicipality(data);
        window.location.reload();
    }

    return (<div>
        <Card className="p-2 mb-8">
            <Row>
                <Col sm="10" md="4" lg="4">
                    <label htmlFor="condition" className="form-label">Provinces</label>
                    <Input
                        bsSize="sm"
                        id="provinces"
                        name="provinces"
                        type="select"
                        defaultValue={province.name}
                        onChange={(event) => handleChangeSelect(event)}
                    >
                        {provinces.map((province) => {
                            return (<option value={province.name} key={'province_' + province.name}>
                                {province.name}
                            </option>)
                        })}
                    </Input>
                </Col>
                <Col sm="3">
                    <label htmlFor="name" className="form-label">Municipality filter</label>
                    <Input
                        bsSize="sm"
                        id="searchTerm"
                        name="searchTerm"
                        type="text"
                        value={searchState.searchTerm}
                        onChange={handleFilterMunicipality}
                    />
                </Col>
            </Row>
        </Card>
        {
            isCreateShow &&
            <Card className="p-2">
                <Row>
                    <Col sm="3">
                        <label htmlFor="name" className="form-label">Name *</label>
                        <Input
                            bsSize="sm"
                            id="name"
                            name="name"
                            type="text"
                            value={municipalityState.name}
                            onChange={(event) => handleChangeTextField(event, 'name')}
                        />
                    </Col>
                    <Col sm="2">
                        <label htmlFor="name" className="form-label">Latitude *</label>
                        <Input
                            bsSize="sm"
                            id="latitude"
                            name="latitude"
                            type="text"
                            value={municipalityState.latitude}
                            onChange={(event) => handleChangeTextField(event, 'latitude')}
                        />
                    </Col>
                    <Col sm="2">
                        <label htmlFor="name" className="form-label">Longitude *</label>
                        <Input
                            bsSize="sm"
                            id="longitude"
                            name="longitude"
                            type="text"
                            value={municipalityState.longitude}
                            onChange={(event) => handleChangeTextField(event, 'longitude')}
                        />
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'flex-end'}}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            onClick={handleCreateMunicipality}
                            disabled={!municipalityState.name.length || !municipalityState.latitude.length || !municipalityState.longitude.length}>Create</Button>
                        <Button className="ml-8" outline color="secondary" size="sm" onClick={() => {
                            setCreateShow(!isCreateShow);
                            setSearchState({municipalities: clone(municipalities), searchTerm: ''});
                        }}>Close</Button>
                    </Col>
                </Row>
            </Card>
        }
        <div className="province__municipalities">
            {
                searchState && searchState.municipalities?.length > 0 &&
                searchState.municipalities.map((municipality: Municipality) =>
                    <Card className="municipality-card" key={municipality.name}>
                        {municipality.name}
                    </Card>
                )
            }
            {
                !isCreateShow && searchState.municipalities?.length === 0 &&
                <Button color="primary" size="sm" onClick={() => setCreateShow(!isCreateShow)}>Create municipality</Button>
            }
        </div>
    </div>)
});

export default Provinces;
