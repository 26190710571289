export const clone = (obj: any) => {
    if (!obj) {
        return null;
    }

    return JSON.parse(JSON.stringify(obj));
}

export const extraIconsMap = {
    '1': 'icon-balcony',
    '2': 'icon-snowflake',
    '3': 'icon-garden',
    '4': 'icon-waves',
    '5': 'icon-elevator',
    '6': 'icon-chair-sun',
    '7': 'icon-auto-key',
    '8': 'icon-basement',
    '9': 'icon-equipped-kitchen',
    '10': 'icon-central-heating',
    '11': 'icon-terrace',
}

export const currencyFormatter = (value: number, withSymbol?: boolean) => {
    const formattedValue = Intl.NumberFormat("es-ES", { style: "currency", currency: "EUR", minimumFractionDigits: 0 }).format(value);
    if (!withSymbol) {
        return formattedValue.substr(0,formattedValue.length-1);
    }

    return formattedValue.substr(formattedValue.length-1) + formattedValue.substr(0,formattedValue.length-1)
}
