import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {auth} from '../services/Firebase';
import {ProvinceStore, provinceStore} from './components/provinceStore';
import {UsersStore, usersStore} from './components/usersStore';

class Store {
    isLoading: boolean = true;
    firebaseAuth = auth;
    provinceStore: ProvinceStore = provinceStore;
    usersStore: UsersStore = usersStore;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            provinceStore: observable,
            setLoading: action,
            usersStore: observable,
        });

        this.firebaseAuth.onAuthStateChanged((user) => this.load(user));
    }

    async load(user: any) {
        this.setLoading(true);
        await usersStore.loadStore(user);
        if (this.usersStore.isAuthorized) {
            await provinceStore.loadStore();
        }
        this.setLoading(false);
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }
}

export const store = new Store();
export const storeContext = createContext(store);
